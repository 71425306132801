import useUrl from '@resources/js/app/composables/useUrl.js';

const { unsetUrlParam } = useUrl();

export default $wire => ({
    scrollableList: null,
    fetchingPage: false,

    init() {
        const self = this;
        self.scrollableList = document.getElementById('scrollableList');

        window.addEventListener('clearMapFilters', function (e) {
            self.clearMapFilters();
            window.location.reload();
        });
    },

    handleScroll() {
        let searchPage = document.getElementById('search-listing-results');
        let scrollY = this.scrollableList.scrollTop;

        if (scrollY >= 100) {
            searchPage.classList.add('scrolled-page');
        } else {
            searchPage.classList.remove('scrolled-page');
        }
    },

    toggleShowMap(state) {
        if (!state) {
            this.clearMapFilters();
        }
        $wire.toggleShowMap(state);
    },

    clearMapFilters() {
        unsetUrlParam('map-zoom', true);
        unsetUrlParam('map-latitude', true);
        unsetUrlParam('map-longitude', true);
        unsetUrlParam('map-area', true);
    },
});
