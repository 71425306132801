export default ($wire, blockAtTop = false) => ({
    loading: false,
    showAdvanceFilters: $wire.entangle('showAdvanceFilters').live,
    isMobile: $wire.mobile,
    atTop: blockAtTop,
    showMobileFilters: false,

    init() {
        const self = this;
        // Set loading state with delay
        let timeoutId = null;
        window.addEventListener('toggleMobileFilters', function (e) {
            self.toggleMobileFilters();
        });

        window.addEventListener('setTopFiltersLoading', function (e) {
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
            const value = e.detail.value;
            if (!value) {
                timeoutId = setTimeout(function () {
                    self.loading = e.detail.value;
                }, 100);
            } else {
                self.loading = e.detail.value;
            }
        });
    },

    toggleMobileFilters() {
        this.showMobileFilters = !this.showMobileFilters;
        /* Prevent window scroll */
        document.body.style.overflow = this.showMobileFilters ? 'hidden' : 'auto';
    },

    toggleAdvanceFilters() {
        this.showAdvanceFilters = !this.showAdvanceFilters;
        document.body.style.overflow = 'auto';
    },
});
