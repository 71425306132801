export default $wire => ({
    initialised: false,
    open: false,
    selectedValues: [],
    defaultSelectedValues: $wire.entangle('selectedValues'),
    openedSublists: $wire.entangle('openedSublists'),
    minSearchChars: $wire.entangle('minSearchChars'),
    results: $wire.entangle('results'),
    search: $wire.entangle('search'),
    searchResults: $wire.entangle('searchResults'),
    shouldScrollLocationToTop: $wire.get('shouldScrollLocationToTop'),

    init() {
        this.selectedValues = this.defaultSelectedValues;
        this.openedSublists = Object.values(this.openedSublists);
        this.initialised = true;
    },

    // Can be optimized
    async selectOption(id, depth, label, parentId, locationId) {
        this.setLoading(true);
        let tempSelectedValues = this.selectedValues;

        if (!this.isSelected(id)) {
            tempSelectedValues.push({ id, label, depth, location_id: locationId });

            const parents = parentId.split('_');
            for (let i = 0; i < parents.length; i++) {
                const tempParent = parents.slice(0, i + 1).join('_');
                if (this.isSelected(tempParent)) {
                    tempSelectedValues = tempSelectedValues.filter(item => item.id !== tempParent);
                }
            }

            const selectedChildren = this.getChildrenSelected(id);
            for (const child of selectedChildren) {
                this.unselectLocation(child.id, tempSelectedValues);
            }

            this.selectedValues = tempSelectedValues;
            await $wire.dispatch('selectLocations', { newSelectedValues: tempSelectedValues });
        }
        this.setLoading(false);
    },

    async removeOption(id) {
        this.setLoading(true);
        this.selectedValues = this.unselectLocation(id, this.selectedValues);
        await $wire.dispatch('removeLocations', { newSelectedValues: this.selectedValues });
        this.setLoading(false);
    },

    async removeOption(id) {
        this.setLoading(true);
        this.selectedValues = this.unselectLocation(id, this.selectedValues);
        await $wire.dispatch('removeLocations', { newSelectedValues: this.selectedValues });
        this.setLoading(false);
    },

    async clearSelections() {
        this.setLoading(true);
        this.selectedValues = [];
        await $wire.dispatch('clearLocations');
        this.setLoading(false);
    },

    unselectLocation(id, selectedValues) {
        return selectedValues?.filter(item => item.id !== id);
    },

    toggleDropdown(id) {
        if (this.forceOpenModal()) {
            this.open = true;
        } else {
            this.open = !this.open;

            if (this.shouldScrollLocationToTop) {
                setTimeout(() => this.scrollToField(id), 200);
            }
        }
    },

    forceOpenModal() {
        return this.displaySuggestions();
    },

    isSelected(id) {
        if (!this.anyElementSelected()) {
            return false;
        }

        return this.selectedValues.some(item => item.id === id);
    },

    anyElementSelected() {
        return this.selectedValues.length > 0;
    },

    displaySuggestions() {
        return this.search !== null && this.search.length >= parseInt(this.minSearchChars);
    },

    scrollToField(fieldId) {
        let field = document.getElementById(fieldId);
        if (field) {
            window.scrollTo({
                top: field.getBoundingClientRect().top + window.pageYOffset - 65,
                behavior: 'smooth',
            });
        }
    },

    async openSublist(id, depth) {
        this.openedSublists.push(id);
        await $wire.getChildrenLocations({ id, depth });
    },

    closeSublist(id) {
        this.openedSublists = this.openedSublists.filter(item => item != id);
    },

    isSublistOpen(id) {
        return this.openedSublists.includes(id);
    },

    hasAnyChildSelected(id) {
        let selected = this.getChildrenSelected(id);

        return selected.length > 0;
    },

    getChildrenSelected(id) {
        return this.selectedValues?.filter(element => element.id.startsWith(id + '_'));
    },

    setLoading(value) {
        window.dispatchEvent(new CustomEvent('setTopFiltersLoading', { detail: { value } }));
    },
});
